var productListValues = function (itemsPerPage, isUserRegistered) {
    var itemsPerPage = itemsPerPage || 50;
    var isUserRegistered = isUserRegistered == "True" ? true : false;
    var pagePattern = /page=([0-9])*/;
    var itemsPerPagePattern = /PS=[0-9]*/;
    var subSearchPattern = /ss=[^&]*/;
    var productQuantityPattern = /\/mq\/[0-9]*/;
    var sortByPattern = /fs=[^&]*&csd=(ASC|DESC)/;
    var pricePattern = /\/p\/[0-9]*-[0-9]*/;
    var colorPattern = /\/c\/[0-9-]*/;
    var ratingPattern = /\/r\/[0-5-]*/;
    var prodTimePattern = /\/s\/[0-9]*/;
    var imprintLocationCountPattern = /\/l\/[0-9]*/;
    var imprintColorCountPattern = /\/m\/[0-9]*/;
    var tagPattern = /\/t\/[0-9-]*/;
    var paramPattern = /\/[cprlmst]\/[0-9]/;

    var sortMap = [{ v: "string:-RelevenceScore", q: "fs=Relevance&csd=DESC", meta: "" },
    { v: "string:SellPrice1", q: "fs=LowestSellPrice&csd=ASC", meta: "Price Low-High" },
    { v: "string:-SellPrice1", q: "fs=LowestSellPrice&csd=DESC", meta: "Price High-Low" },
    { v: "string:MinOrderQty", q: "fs=MinOrderQty&csd=ASC", meta: "Minimum Quantity" },
    { v: "string:ProductName", q: "fs=ProductName&csd=ASC", meta: "Product Name" },
    { v: "string:ProductionTime", q: "fs=ProductionTime&csd=ASC", meta: "Production Time" },
    { v: "string:-AverageRating", q: "fs=AverageRating&csd=DESC", meta: "Rating" }];

    var replaceCurrentUrl = function (newUrl, newTitle, newDescription, newKeywords) {
        var old = window.location.href;
        var oldTitle = document.title;
        var oldKeywords = document.querySelectorAll('meta[name="keywords"]')[0] ? document.querySelectorAll('meta[name="keywords"]')[0].getAttribute('content') : '';
        var oldDescription = document.querySelectorAll('meta[name="description"]')[0].getAttribute('content');
        var urlArray = old.split('/');
        var prot = urlArray[0];
        var host = urlArray[2];
        var url = prot + '//' + host;
        if ((newUrl.indexOf('?') == 0 && old.indexOf('?') > -1 && old.slice(old.indexOf('?')) != newUrl) || old.replace(url, '') != newUrl) {
            history.pushState({ title: oldTitle, keywords: oldKeywords, description: oldDescription }, '', old);
            history.replaceState({ title: newTitle, keywords: newKeywords, description: newDescription }, '', newUrl);
            document.title = newTitle;
        }
    };

    function getItemsPerPage() { return itemsPerPage; }
    function getIsUserRegistered() { return isUserRegistered; }

    return {
        GetItemsPerPage: getItemsPerPage,
        GetIsUserRegistered: getIsUserRegistered,
        PagePattern: pagePattern,
        ItemsPerPagePattern: itemsPerPagePattern,
        SubSearchPattern: subSearchPattern,
        ProductQuantityPattern: productQuantityPattern,
        SortByPattern: sortByPattern,
        PricePattern: pricePattern,
        ColorPattern: colorPattern,
        RatingPattern: ratingPattern,
        ProdTimePattern: prodTimePattern,
        ImprintLocationCountPattern: imprintLocationCountPattern,
        ImprintColorCountPattern: imprintColorCountPattern,
        TagPattern: tagPattern,
        SortMap: sortMap,
        ReplaceCurrentUrl: replaceCurrentUrl,
        ParamPattern: paramPattern,
    };
};