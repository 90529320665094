var prodListTagline = function() {
	let targetTagline,
	
	init = () => {
		const prodContainer = document.getElementById("productContainer");
		if(prodContainer) prodContainer.addEventListener("click", events);
	},
	events = (e) => {
		if(e.target.classList.contains("productListItemTagline")) toggle(e);
	},
	toggle = (e) => {
		e.preventDefault();
		targetTagline = e.target;
		if(e.target.classList.contains("showLine")) hide();
		else show();
	},
	show = () => {
		targetTagline.classList.add("showLine");
	},
	hide = () => {
		targetTagline.classList.remove("showLine");
	};
	
	return{
		Init: init
	};
};

(function(){
	const listTaglines = new prodListTagline();
	listTaglines.Init();
})();