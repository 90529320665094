/* 
	Molly Lentz 7.20.18
	Adapted from: https://gomakethings.com/how-to-add-transition-animations-to-vanilla-javascript-show-and-hide-methods/
	on pageload:					const accountAccordions = new getAccordions4i();
	enable:  						accountAccordions.init();
	open/close are also available:  	accountAccordions.close(document.getElementById("SaleSection"));
	css needed: components/_accordion.scss
	see styelguide for HTML example
*/

var getAccordions4i = function(){
	const init = (container) =>{
		const accordionHdrs = container.querySelectorAll(".accordionHeader");
		for(let i = 0; i < accordionHdrs.length; i++){
			accordionHdrs[i].removeEventListener("click", togglePanel);
			accordionHdrs[i].addEventListener("click", togglePanel);
	  	}
	};
	
	const open = (accordBtn) => {
		const closedPanel = accordBtn;
		const headerBtn = closedPanel.previousElementSibling.firstElementChild;
		
		const getHeight = () => {
			closedPanel.style.display = "block";
			let heightMeasurement = closedPanel.scrollHeight + "px"; 
			closedPanel.removeAttribute("style"); 
			return heightMeasurement;
		};
		const height = getHeight();
		
		closedPanel.style.height = height; 
		closedPanel.classList.add("open"); 
		closedPanel.setAttribute("aria-hidden", "false");
		headerBtn.setAttribute("aria-expanded", "true");
	
		// This is important so content can scale responsively
		setTimeout(() => closedPanel.style.height = '', 300);
	};
	
	const close = (accordBtn) =>{
		const openPanel = accordBtn;
		const headerBtn = openPanel.previousElementSibling.firstElementChild;
			
		// Give the element a height to change from
		openPanel.style.height = openPanel.scrollHeight + 'px';		
		openPanel.setAttribute("aria-hidden", "true");
		headerBtn.setAttribute("aria-expanded", "false");

		setTimeout(() => openPanel.style.height = '0', 1);
		setTimeout(() => openPanel.classList.remove("open"), 300);
	};
	
	const openAll = (container) =>{
		if(container != null){
			const specificAccordHeaders = container.querySelectorAll(".accordionHeader");
			for(let i = 0; i < specificAccordHeaders.length; i++){
		    		const accordionPanel = specificAccordHeaders[i].parentNode.nextElementSibling;
				open(accordionPanel);
		  	}	
		}
	};
	
	const closeAll = (container) =>{
		if(container != null){
			const specificAccordHeaders = container.querySelectorAll(".accordionHeader");
			for(let i = 0; i < specificAccordHeaders.length; i++){
		    		const accordionPanel = specificAccordHeaders[i].parentNode.nextElementSibling;
				close(accordionPanel);
		  	}		
	  	}
	};
	
	const togglePanel = (e) => {
		e.preventDefault();
		const accordionPanel = e.currentTarget.parentNode.nextElementSibling;
		if (accordionPanel.classList.contains("open")) {
			close(accordionPanel);
			return;
		}else {
			open(accordionPanel);
		}
	};
	
	return {
		init: init,
		close: close,
		open: open,
		openAll: openAll,
		closeAll: closeAll
	};
};