const StarRating4i = function () {
	let triggers,
	
	init = () => {
		triggers = document.getElementsByClassName("miniReviewLink");
		for(var i = 0; i < triggers.length; i++){
			triggers[i].removeEventListener('click', openRatingsBreakdown);
			triggers[i].addEventListener('click', openRatingsBreakdown);
		}
	},
	openRatingsBreakdown = (e) => {
		e.preventDefault();
		let reviewTrigger = e.currentTarget;
		if (reviewTrigger.getAttribute("aria-expanded") == "false") open(reviewTrigger);
		else close(reviewTrigger);
	},
	open = (trigger) => {
		closeAnyOpen();
		let dropdown = trigger.nextElementSibling;
		let dropdownLink = dropdown.getElementsByTagName("a")[0];

		trigger.setAttribute("aria-expanded", "true");
		dropdown.classList.remove("hide");
		document.addEventListener('keydown', closeOnEsc);
		//if main product detail rating, we will go to current page rating, else follow the href
		const isDetailsPage = dropdown.parentNode.parentNode.parentNode.classList.contains("ratingPlaceholder");
		if(isDetailsPage) dropdownLink.addEventListener('click', goToReviews);
	},
	close = (trigger) => {
		let dropdown = trigger.nextElementSibling;
		trigger.setAttribute("aria-expanded", "false");
		dropdown.classList.add("hide");
		document.removeEventListener('keydown', closeOnEsc);
	},
	closeAnyOpen = () => {
		for(var i = 0; i < triggers.length; i++){
			if(triggers[i].getAttribute("aria-expanded") == "true") close(triggers[i]);
		}
	},
	closeOnEsc = (e) => {
		if (e.keyCode == 27) closeAnyOpen();
	},
	waitForTabsToAppear = (activatedTabId) => {
		document.querySelector("#detailTabs").scrollIntoView();
		
		//allow the tabs to 'appear'
		const callback = function(mutationsList, observer) {
			for (let i = 0; i < mutationsList.length; i++) {
 				if (mutationsList[i].type === 'childList') {
					let tab = document.getElementById(activatedTabId);
 					tab.click();
 					tab.focus();
 					tabObserver.disconnect();
				}
			}
		};
		const tabObserver = new MutationObserver(callback);
		tabObserver.observe(document.getElementById('detailTabs'), { 
			childList: true, 
			subtree: true 
		});
	},
	goToReviews = (e) => { 
		e.preventDefault();
		if (document.querySelector('a.anchor[name="reivews"]')) { return; }
		closeAnyOpen();

		//if the tabs haven't appeared on the page yet
		const reviewTab = document.getElementById("reviewsTS") ? document.getElementById("reviewsTS") : "";
		if(reviewTab == ""){
			waitForTabsToAppear("reviewsTS");
		}else{
			reviewTab.click();
			reviewTab.focus();
		}
	};
	return {
		Init: init
	};
};